@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap');

body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
    background-color: #f4f4f4;
}

.container {
    font-family: 'Inter', sans-serif;
    width: 50%;
    max-width: 600px;
    height: 80vh;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow-y: auto; /* Allow scrolling for the body section */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.header {
    font-family: 'Inter', sans-serif;
    position: sticky;
    top: 0;
    background-color: #f9f9f9;
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    z-index: 100; /* Ensures header stays above scrolling content */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    margin-bottom: -30px;
    height: 140px; /* Increased logo size */
}

.eu-flag {
    height: 80px;
    margin-bottom: -10px;
}

.header {
    display: flex;
    flex-direction: row;
    align-content: center;
}

.header h1 {
    font-family: 'Inter', sans-serif;
    font-size: 30px;
}

.body {
    font-family: 'Inter', sans-serif;
    padding: 20px;
    flex: 1;
    overflow-y: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}


.message {
    font-family: 'Inter', sans-serif;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    line-height: 1.5;
}

.message.bot {
    font-family: 'Inter', sans-serif;
    background-color: #e0e0e0;
    align-self: flex-start;
}

.message.user {
    font-family: 'Inter', sans-serif;
    background-color: #0084ff;
    color: white;
    align-self: flex-end;
}

.options {
    font-family: 'Inter', sans-serif;
    display: flex;
    gap: 10px;
    max-width: 60%;
    margin-top: 10px;
    flex-direction: column;
}


.option-button {
    font-family: 'Inter', sans-serif;
    padding: 10px 15px;
    border: none;
    background-color: #0084ff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.option-button:hover {
    background-color: #005bb5;
}

.footer {
    font-size: 12px;
    color: #555;
    margin: 10px;
    text-align: center;
}

.start-over-button {
    background-color: #292626;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px auto;
    display: block;
    margin-bottom: -20px;
    transition: background-color 0.3s;
}

.start-over-button:hover {
    background-color: #858585;
}

#initial_button_left {
    font-family: 'Inter', sans-serif;
    margin-right: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #0084ff;
    color: white;
    border-radius: 10px;
    border: none;
}

#initial_button_left:hover {
    background-color: #005bb5;
    cursor: pointer;
}

#initial_button_right:hover {
    background-color: #005bb5;
    cursor: pointer;
}

#initial_button_right {
    font-family: 'Inter', sans-serif;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #0084ff;
    color: white;
    border-radius: 10px;
    border: none;
}

.footer {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 20px;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
}

#ask_button {
    font-family: 'Inter', sans-serif;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #0084ff;
    color: white;
    border-radius: 10px;
    margin-top: 10px;
    border: none;   
}

#ask_button:hover {
    background-color: #005bb5;
    cursor: pointer;
}

#back_to_start {
    font-family: 'Inter', sans-serif;
    padding: 10px 20px;
    font-size: 13px;
    background-color: #292626;
    color: white;
    border-radius: 10px;
    margin-top: 10px;
    border: none;   
}

#back_to_start:hover {
    background-color: #858585;
    cursor: pointer;
}

#three_dots_loading {
    margin-left: 67px;
    margin-top: -10px;
}

#ask_a_question {
    font-family: 'Inter', sans-serif;
}